import { FC, useCallback } from 'react';
import { FilterProps } from './FilterProps';
import GenericFilter from './GenericFilter';
import { useLazyQuery } from '@apollo/client';
import { graphql } from 'gql.tada';

export const ResourcePicklistFilterQuery = graphql(`
  query ResourcePicklistFilter($tfId: UUID!) {
    clientFormsReferences(templateFormId: $tfId, templateFormTypes: [ASSET, DEFAULT, RESOURCE, STARTUP, SUB_FORM, SUB_FORM_WITH_APPROVAL]) {
      id
      displayTitle
    }
  }
`);

const ResourcePicklistFilter: FC<FilterProps> = (props) => {
  const [loadQuery] = useLazyQuery(ResourcePicklistFilterQuery);

  const loadPage = useCallback(
    (selectedTemplateId: string, _: unknown, __: unknown, pageNum: number) => {
      return (
        loadQuery({
          variables: {
            tfId: selectedTemplateId,
          },
        }).then((res) => {
          return {
            meta: {
              totalCount: res.data?.clientFormsReferences?.length ?? 0,
              pageNumber: pageNum,
              success: true,
              code: 200,
              message: '',
            },
            data: res.data?.clientFormsReferences?.map((x, i) => ({ id: i, text: x!.displayTitle as string, value: x!.id as string })) ?? [],
            status: 200,
            headers: {},
          };
        }) ?? Promise.resolve({ data: [], totalCount: 0, pageNumber: 0 })
      );
    },
    [loadQuery],
  );

  return <GenericFilter {...props} loadPage={loadPage} />;
};

export default ResourcePicklistFilter;
